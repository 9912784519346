@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Arial';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .form-container {
  text-align: left;
  max-width: 300px;
  margin: 20px auto;
  font-size: 16px;
} */

/* form {
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 20px;
  font-size: 24px;
  width: 400px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
} */
